<template>
  <Toast />
  <div v-if="loading">
    <ProgressSpinner />
  </div>

  <div
    v-else
    :class="['grid', 'flex-nowrap', getSelectedPlanClass()]"
  >
    <Dialog
      v-model:visible="displayProfileSelector"
      modal
      header="Select profiles to enable"
      :closable="true"
      :style="{ width: '50vw' }"
    >
      <div class="flex flex-wrap pb-5">
        <div class="target-dropdown">
          <p>Please select a maximum of {{ selectedPlan.maxNumberOfProfiles }} profiles. If there are more than {{ selectedPlan.maxNumberOfProfiles }} enabled profiles at the plan renewal, only the selected profiles will remain active; the others will be disabled.</p>
          <MultiSelect
            v-model="values.selectedProfiles"
            data-key="profileId"
            class="full-dropdown"
            :options="profiles"
            option-value="profileId"
            placeholder="Select profiles to enable..."
            :option-label="getProfileName"
            :filter="true"
            display="chip"
            :selection-limit="selectedPlan.maxNumberOfProfiles"
            @change="validate('selectedProfiles')"
          >
            <template #option="slotProps">
              <span>{{ `${slotProps.option.name} - ${slotProps.option.countryCode}` }}</span><span class="entity-id">{{ `${slotProps.option.entityId.replace("ENTITY", "")}` }}</span>
            </template>
          </MultiSelect>
        </div>
      </div>
      <div class="flex justify-content-end w-full">
        <Button
          size="small"
          @click="handleConfirmSelectedProfiles"
        >
          Confirm
        </Button>
      </div>
    </Dialog>
    <div
      class=" plan-marketing-labels"
    >
      <div class="flex align-items-end gap-3 font-bold profiles-allowed">
        <span>Amazon profiles allowed:</span>
      </div>
      <div class="flex align-items-baseline gap-3 mt-3 font-bold access-to-comunity">
        <span>Access to Comunity:</span>
      </div>
      <div class="flex align-items-baseline gap-3 font-bold email-support">
        <span>Email support:</span>
      </div>
      <div class="flex align-items-baseline gap-3 font-bold onboarding-call">
        <span>Onboarding call:</span>
      </div>
      <div class="flex align-items-baseline gap-3 font-bold">
        <span>Checkpoint  calls included:</span>
      </div>
    </div>
    <div
      v-for="currentPlan in currentPlans.sort((plan1, plan2) => plan1.basePrice - plan2.basePrice)"
      :key="currentPlan.id"
    >
      <div class="p-1 plan-column">
        <div
          class="shadow-2 p-3 h-full flex flex-column surface-card"
          style="border-radius: 6px"
        >
          <div class="text-900 font-bold text-2xl mb-4">
            {{ getPlanName(currentPlan.planType) }}
          </div>
          <div class="flex align-items-baseline gap-3">
            <span
              class="font-bold text-900 plan-cost"
            >{{ getCurrencySymbol() }}{{ currentPlan.basePrice }}</span><span class="text-sm">/month</span>
          </div>
          <div
            v-if="currentPlan.variableRate !== 'NONE'"
            class="flex align-items-baseline gap-3"
          >
            <span class="text-sm font-semibold">{{ formatVariableRate(currentPlan.variableRate) }}</span>
          </div>
          <div
            v-else
            class="flex align-items-baseline gap-3"
          >
            <span
              class="text-sm font-semibold visibility-hidden"
            >None</span>
          </div>
          <div class="flex align-items-baseline gap-3 mt-3 mb-4 h-2rem ">
            <span class="text-sm">{{ currentPlan.planType === "ENTERPRISE" ? "Over" : "Up to" }} ${{ currentPlan.maxAdSpend ?? "5000" }}/month of ad spend</span>
          </div>

          <template v-if="isCurrentPlan(currentPlan, plan, pricing)">
            <Button
              class="p-button-secondary my-3 text-l plan-button"
              label="Current plan"
              :disabled="loadingPayment"
              @click="showCancelPopup($event)"
            />
            <ConfirmPopup>
              <template #message="slotProps">
                <div class="flex p-4">
                  <i
                    :class="slotProps.message.icon"
                    style="font-size: 1.5rem"
                  />
                  <p class="pl-2">
                    {{ slotProps.message.message }}
                  </p>
                </div>
              </template>
            </ConfirmPopup>
          </template>
          <template v-else-if="isSelectedPlan(currentPlan)">
            <Button
              class="p-button-secondary my-3 text-l plan-button"
              label="Selected plan"
              :disabled="loadingPayment"
            />
          </template>
          <template v-else>
            <Button
              :label="plan === null ? 'Select plan' : 'Switch Plan'"
              :class="[ 'my-3', 'text-l', 'plan-button']"
              :disabled="loadingPayment"
              @click="selectPlan(currentPlan)"
            />
          </template>

          <div class="flex align-items-baseline my-3">
            <span
              v-if="currentPlan.maxNumberOfProfiles !== null"
              class="text-sm"
            >Up to {{ currentPlan.maxNumberOfProfiles }} </span>
            <span
              v-else
              class="text-sm"
            >Unlimited</span>
          </div>

          <div class="flex align-items-baseline my-3">
            <i class="pi pi-check" />
          </div>

          <div class="flex align-items-baseline my-3 h-2rem">
            <span
              class="text-sm"
            >Response in up to {{ getWorkingHours(currentPlan.planType) }} working hours</span>
          </div>

          <div class="flex align-items-baseline my-3">
            <i class="pi pi-check" />
          </div>

          <div class="flex align-items-baseline my-3 h-2rem">
            <span
              class="text-sm"
            >{{ getCheckpointCalls(currentPlan.planType) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="selectedPlan !== null"
    >
      <div class="p-1 selected-plan-column">
        <div
          class="shadow-2 p-3 h-full flex flex-column surface-card"
          style="border-radius: 6px"
        >
          <div class="text-900 font-bold mb-4">
            Your new plan
          </div>
          <div class="grid">
            <span class="font-bold col-6">{{ getPlanName(selectedPlan.planType).name }}</span>
            <span class="font-bold col-6 text-right">${{ selectedPlan.basePrice }}{{ selectedPlan.planType === "ENTERPRISE" ? " + 2%" : "" }}</span>
          </div>
          <div>
            - {{ selectedPlan.planType === "ENTERPRISE" ? "Over" : "Up to" }} ${{ selectedPlan.maxAdSpend ?? "5000" }}/month of ad spend
          </div>
          <div class="mb-2">
            - {{ selectedPlan.maxNumberOfProfiles ?? "Unlimited" }} profiles
          </div>

          <div
            v-if="selectedPlan.planType === 'ENTERPRISE' && (pricing.status === 'NO_PLAN' || pricing.status === 'TRIAL')"
            class="grid"
          >
            <span class="text-sm col-7 flex align-items-center"><span class="mr-1">Estimated ad spend</span> <span
              v-tooltip="'Insert the ad spend of your Sponsored Product campaigns in the last 30 days to get your estimated monthly fee.'"
              class="pi pi-info-circle"
            /></span>
            <InputNumber
              id="estimatedAdSpend"
              v-model="estimatedAdSpend"
              :min="1"
              :min-fraction-digits="0"
              :max-fraction-digits="0"
              :use-grouping="false"
              class="col-5 ad-spend-input"
              input-class="estimated-ad-spend"
              prefix="$"
              locale="en-US"
              :allow-empty="false"
            />
          </div>
          <div
            v-else-if="selectedPlan.planType === 'ENTERPRISE' && (pricing.status === 'ACTIVE' || pricing.status === 'CANCELLED')"
            class="grid"
          >
            <span class="font-bold col-8">Current period ad spend</span>
            <span
              class="font-bold col-4 text-right"
            >
              ${{ formatNumber(currentAdSpend) }}
            </span>
          </div>

          <div class="grid mt-2">
            <span class="">Discount code:</span>
            <div class="flex">
              <InputText
                id="selectedDiscount"
                v-model="selectedDiscount"
                class="w-full"
              />
              <div class="flex align-items-center">
                <Button
                  label="Apply"
                  class="p-button-secondary p-button-sm ml-1"
                  autofocus
                  :disabled="loadingPayment"
                  @click="applyDiscountCode"
                />
              </div>
            </div>
          </div>

          <hr>

          <div
            v-if="selectedPlan.planType !== 'ENTERPRISE'"
            class="grid"
          >
            <span class="font-bold col-4">Total</span>
            <span
              class="font-bold col-8 text-right"
            >${{ formatNumber(selectedPlanTotal) }} <span
              v-if="discountPercentage !== null"
            >(-{{ discountPercentage }}%)</span>
            </span>
          </div>
          <div
            v-else
            class="grid"
          >
            <span
              v-if="selectedPlan.planType === 'ENTERPRISE' && (pricing.status === 'NO_PLAN' || pricing.status === 'TRIAL')"
              class="font-bold col-6"
            >Estimated Total</span>
            <span
              v-else
              class="font-bold col-6"
            >Total</span>
            <span
              class="font-bold col-6 text-right"
            >
              ${{ formatNumber(calculateEstimatedAdSpend()) }} <span
                v-if="discountPercentage !== null"
              >(-{{ discountPercentage }}%)</span>
            </span>
          </div>

          <hr>

          <div>
            <span
              class="text-sm"
            >- You'll pay ${{ formatNumber(selectedPlanPayNow) }} now.</span>
          </div>
          <div>
            <span
              v-if="selectedPlan.planType !== 'ENTERPRISE'"
              class="text-sm"
            >- You'll pay ${{ formatNumber(selectedPlanTotal) }} on the following renewal date: {{ getRenewalDate() }}.</span>
            <span
              v-else
              class="text-sm"
            >
              <span v-if="pricing.status === 'NO_PLAN' || pricing.status === 'TRIAL'">
                - You'll pay the estimated total on the following date: {{ getRenewalDate() }}. Please note that the final fee may vary depending on your ad spend.
              </span>
              <span v-else>
                - Your next payment will be on the following date: {{ getRenewalDate() }}. Please note that the final fee may vary monthly depending on your ad spend.
              </span>

            </span>
          </div>
          <div>
            <span class="text-sm">- Your plan is billed monthly.</span>
          </div>
          <div>
            <span class="text-sm">- You can cancel anytime before this date.</span>
          </div>

          <hr>

          <div class="grid">
            <span class="col-9 font-bold">Ad spend autoupgrade <span
              v-tooltip="'Get an automatic upgrade to the next plan if you reach your ad spend limit, so that your bids are optimized even while the ad spend increases.'"
              class="pi pi-info-circle"
            /></span>
            <div class="col-3">
              <InputSwitch
                id="selectedDiscount"
                v-model="selectedAutoUpgrade"
                class=""
              />
            </div>
          </div>

          <div>
            <Accordion>
              <AccordionTab header="See more">
                <p
                  v-if="selectedPlan.planType !== 'ENTERPRISE'"
                  class="text-sm"
                >
                  -In your case, the next plan is the {{ getNextPlan(selectedPlan).name }} with {{ getNextPlan(selectedPlan).value === "ENTERPRISE" ? "Over" : "Up to" }} ${{ currentPlans.find(plan => plan.planType === getNextPlan(selectedPlan).value).maxAdSpend ?? "5000" }}/month. If you reach the limit, you'll pay the difference between the new plan and the old one.
                </p>
                <p class="text-sm">
                  -You'll stay on the higher tier after the auto-upgrade, unless you manually select a lower plan.
                </p>
                <p class="text-sm">
                  -The auto upgrade will keep on happening while the toggle is activated. If you wish to stop it, please deactivate the toggle.
                </p>
              </AccordionTab>
            </Accordion>
          </div>

          <div class="text-center">
            <Button
              :label="plan === null ? 'Confirm plan' : isDowngrading() ? 'Downgrade plan' : 'Pay & Upgrade'"
              :class="[ 'my-3', 'text-l', 'w-9']"
              :disabled="loadingPayment"
              @click="handlePlanBuy()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { array, object } from 'yup';
import A2CAAuthorizedClient from '../../../api/a2caAuthorizedClient';
import { findPlanByType, findNextPlan } from '../../../constants/planConstants';
import { currencySymbolFromCountry } from '../../../utils/formatUtils';
import NUMBER_FORMATTER from '../../../constants/numberConstants';
import { SHOW_PAYMENT_SUCCESS_BANNER } from '../../../constants/storageConstants';

const profileSelectorSchema = object().shape({
  selectedProfiles: array().min(1, 'At least one profile must be selected.'),
});

export default {
  inject: ['query'],
  props: {
    initialSubscription: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      loadingPayment: false,
      currentPlans: null,
      selectedPlan: null,
      selectedDiscount: null,
      selectedAutoUpgrade: false,
      selectedPlanTotal: null,
      selectedPlanPayNow: null,
      discountCodeToApply: null,
      discountPercentage: null,
      estimatedAdSpend: 5000,
      downgradePlan: null,
      profiles: this.$store.state.auth.profiles,
      values: {
        selectedProfiles: [],
      },
      displayProfileSelector: false,
      profileSelectorCallBack: null,
      width: window.innerWidth,
      errors: {
        selectedProfiles: '',
      },
      planTier: {
        BASIC: 1,
        PLUS: 2,
        ADVANCED: 3,
        ENTERPRISE: 4,
      },
    };
  },
  computed: {
    ...mapGetters([
      'plan', 'pricing', 'currentAdSpend', 'enterpriseVariableCost',
    ]),
  },
  async created() {
    this.loading = true;
    const client = new A2CAAuthorizedClient(this.query);

    const planInformation = await client.getPlanInformation();
    const { currentPricing, downgradePlan } = planInformation;
    this.setPricing(currentPricing);
    this.currentPlans = planInformation.plans;
    this.downgradePlan = downgradePlan;
    this.loading = false;
    window.addEventListener('resize', this.resizeWindow);
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeWindow);
  },
  methods: {
    validate(field) {
      profileSelectorSchema
        .validateAt(field, this.values, { abortEarly: true })
        .then(() => {
          this.errors[field] = '';
        })
        .catch((err) => {
          this.errors[field] = err.message;
        });
    },
    selectPlan(selectedPlan) {
      this.selectedPlan = selectedPlan;
      this.selectedPlanTotal = selectedPlan.basePrice;
      this.discountCodeToApply = null;
      this.discountPercentage = null;
      this.selectedDiscount = null;
      this.selectedPlanPayNow = this.planPrice();
    },
    formatPlanDate(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },
    getCurrencySymbol() {
      return currencySymbolFromCountry(this.$store.state.auth.account.country);
    },
    getPlanName(planType) {
      const plan = findPlanByType(planType);
      return plan ? plan.name : '';
    },
    isCurrentPlan(plan, currentPlan, currentPricing) {
      if (this.downgradePlan !== null) {
        return this.downgradePlan === plan?.planType;
      }
      return currentPricing.status === 'ACTIVE' && plan?.planType === currentPlan?.planType;
    },
    isSelectedPlan(currentPlan) {
      return this.selectedPlan !== null && this.selectedPlan.planType === currentPlan.planType;
    },
    showCancelPopup(event) {
      this.$confirm.require({
        target: event.currentTarget,
        acceptClass: 'p-button-danger',
        acceptLabel: 'Cancel plan',
        message: 'Are you sure you want to cancel your current plan subscription?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.cancelPlan();
        },
      });
    },
    async handlePlanBuy() {
      // Reset selected profiles
      this.values.selectedProfiles = [];

      this.profileSelectorCallBack = this.buyPlan;

      const planMaxProfiles = this.selectedPlan.maxNumberOfProfiles;
      if (planMaxProfiles !== null && this.getNumberOfEnabledProfiles() > planMaxProfiles) {
        this.showProfileSelector();
      } else {
        this.values.selectedProfiles = null;
        this.profileSelectorCallBack();
      }
    },
    async showProfileSelector() {
      this.displayProfileSelector = true;
    },
    async buyPlan() {
      const plan = this.selectedPlan;
      const client = new A2CAAuthorizedClient(this.query);
      this.loadingPayment = true;
      try {
        const checkout = await client.buyPlan(plan.planType, this.selectedAutoUpgrade, this.discountCodeToApply, this.values.selectedProfiles);
        if (checkout.url !== null) {
          localStorage.setItem(SHOW_PAYMENT_SUCCESS_BANNER, JSON.stringify(true));
          window.location.href = checkout.url;
        } else {
          localStorage.setItem(SHOW_PAYMENT_SUCCESS_BANNER, JSON.stringify(true));
          this.$router.push({ name: 'myAccountInfo' });
        }
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Payment not successful',
          detail: 'The payment has not been successful. Please try again or contact us.',
          life: 1500,
        });
        console.error(err);
      }
      this.loadingPayment = false;
    },
    async cancelPlan() {
      const client = new A2CAAuthorizedClient(this.query);
      this.loadingPayment = true;
      try {
        const resultPricing = await client.cancelPlan();
        this.setPricing(resultPricing);
        this.$toast.add({
          severity: 'success',
          summary: 'Subscription cancelled.',
          detail: `You have successfully cancelled your subscription to Booksflyer. You will be able to use the tool until ${this.formatPlanDate(resultPricing.disableDate)}`,
          life: 10000,
        });
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error cancelling subscription',
          detail: 'Your subscription could not be cancelled. Please try again.',
          life: 1500,
        });
        console.error(err);
      }
      this.loadingPayment = false;
      console.debug('Subscription cancelled, initial subscription is', this.initialSubscription);
      // this.logout();
      if (this.initialSubscription) {
        console.debug('Redirecting to login after cancelling plan.');
        this.$router.push({ name: 'login' });
      }
    },
    async applyDiscountCode() {
      const client = new A2CAAuthorizedClient(this.query);
      this.loadingPayment = true;
      try {
        const discountToApply = await client.retrieveDiscount(this.selectedDiscount, this.selectedPlan.basePrice);
        const payNowDiscountToApply = await client.retrieveDiscount(this.selectedDiscount, this.planPrice());
        if (discountToApply?.type || payNowDiscountToApply?.type) {
          this.$toast.add({
            severity: 'error',
            summary: 'Invalid discount',
            detail: 'The discount is not valid',
            life: 1500,
          });
        } else {
          this.selectedPlanTotal = discountToApply.appliedDiscountAmount;
          this.discountCodeToApply = discountToApply.discount.code;
          this.discountPercentage = discountToApply.discount.value.value;
          this.selectedPlanPayNow = payNowDiscountToApply.appliedDiscountAmount;
          this.$toast.add({
            severity: 'success',
            summary: 'Discount applied',
            detail: 'Discount applied successfully',
            life: 1500,
          });
        }
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Payment not successful',
          detail: 'The payment has not been successful. Please try again or contact us.',
          life: 1500,
        });
        console.error(err);
      }
      this.loadingPayment = false;
    },
    formatVariableRate(variableRate) {
      const variableRateRegex = /AD_SPEND_(\d)_PERCENT_OVER_(\d+)/;
      const match = variableRate.match(variableRateRegex);
      return `+${match[1]}% of ad spend over $${match[2]}`;
    },
    getWorkingHours(planType) {
      switch (planType) {
        case 'BASIC':
          return 72;
        case 'PLUS':
          return 48;
        case 'ADVANCED':
          return 24;
        case 'ENTERPRISE':
          return 24;
        default:
          return 72;
      }
    },
    getCheckpointCalls(planType) {
      switch (planType) {
        case 'BASIC':
          return '1 call 30 days after setup';
        case 'PLUS':
          return 'Monthly checkpoint call during the first 3 months';
        case 'ADVANCED':
          return 'Monthly checkpoint call during the first 6 months';
        case 'ENTERPRISE':
          return 'Monthly checkpoint calls as needed during lifetime';
        default:
          return '1 call a month after setup';
      }
    },
    isDowngrading() {
      if (!this.plan) { return false; }
      if (!this.selectedPlan) { return false; }

      return this.planTier[this.plan?.planType] - this.planTier[this.selectedPlan?.planType] > 0;
    },
    getRenewalDate() {
      if (this.pricing?.disableDate && this.pricing.status === 'ACTIVE') {
        return dayjs(this.pricing.disableDate).format('YYYY-MM-DD');
      }
      return dayjs().add(1, 'month').format('YYYY-MM-DD');
    },
    getProfileName(profile) {
      return `${profile.name} - ${profile.countryCode} - ${profile.entityId.replace('ENTITY', '')}`;
    },
    getNumberOfEnabledProfiles() {
      const existingProfileIds = this.profiles.map((profile) => profile.profileId);
      const enabledProfileIds = Object.entries(this.$store.state.auth.account.enabledProfiles).filter((entry) => entry[1]).map((entry) => entry[0]);
      return enabledProfileIds.filter((profileId) => existingProfileIds.includes(profileId)).length;
    },
    formatNumber(number) {
      return NUMBER_FORMATTER.format(number);
    },
    planPrice() {
      // Nothing is paid for enterprise plans when you have no plan
      if (this.plan === null && this.selectedPlan.planType === 'ENTERPRISE') {
        return 0;
      }

      if (this.plan === null || this.pricing.status === 'CANCELLED') {
        return this.selectedPlan.basePrice;
      }

      const selectedPlanLevel = findPlanByType(this.selectedPlan.planType).level;
      const currentPlanLevel = findPlanByType(this.plan?.planType).level;
      if (selectedPlanLevel > currentPlanLevel) {
        if (this.selectedPlan.planType === 'ENTERPRISE') {
          return this.calculateEstimatedAdSpend() - this.plan?.basePrice;
        }
        return this.selectedPlan.basePrice - this.plan?.basePrice;
      }
      return 0;
    },
    getSelectedPlanClass() {
      if (this.width >= 1300) {
        return 'justify-content-center';
      }
      return this.selectedPlan === null ? 'justify-content-center' : '';
    },
    getNextPlan(selectedPlan) {
      return findNextPlan(selectedPlan);
    },
    // eslint-disable-next-line vue/no-unused-properties
    getAdSpendBasedPay() {
      if (this.discountPercentage === null) {
        return 349;
      }

      return this.formatNumber(349 * (1 - this.discountPercentage / 100));
    },
    resizeWindow(e) {
      this.width = e.target.innerWidth;
    },
    calculateEstimatedAdSpend() {
      if (this.selectedPlan.planType === 'ENTERPRISE' && (this.pricing.status === 'NO_PLAN' || this.pricing.status === 'TRIAL')) {
        return (this.selectedPlan.basePrice + 0.02 * (Math.max(0, this.estimatedAdSpend - 5000))) * (1 - this.discountPercentage / 100);
      }
      return (this.selectedPlan.basePrice + this.enterpriseVariableCost) * (1 - this.discountPercentage / 100);
    },
    handleConfirmSelectedProfiles() {
      profileSelectorSchema
        .validate(this.values, { abortEarly: false })
        .then(() => {
          this.profileSelectorCallBack();
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors[error.path] = error.message;
          });
          this.$toast.add({
            severity: 'error',
            summary: 'No profiles selected',
            detail: 'At least one profile must be selected.',
            life: 3000,
          });
        });
    },
    ...mapMutations(['setPricing', 'logout', 'setShowPaymentSuccessBanner']),
  },

};
</script>

<style scoped>
  .target-dropdown {
      flex: 2;
    }
  .plan-cost {
    font-size: 2.4rem;
  }
  .gap-3 {
    gap: 1rem;
  }
  .visibility-hidden {
    visibility: hidden;
  }

  .plan-marketing-labels {
    margin-top: 18.3rem;
    width: 13.5rem;
    min-width: 13.5rem;
    max-width: 13.5rem;
  }

  .inline-grid {
    display: inline-grid !important;
  }

  .full-dropdown {
      min-width: 25rem;
      max-width: 25rem;
    }

    .plan-column {
      width: 15.2rem;
      min-width: 15.2rem;
      max-width: 15.2rem;
      height: 36rem;
    }

    .selected-plan-column {
      width: 20rem;
      min-width: 20rem;
      max-width: 20rem;
    }

    .profiles-allowed {
      margin-bottom: 28px;
    }

    .access-to-comunity {
      margin-bottom: 28px;
    }

    .email-support {
      margin-bottom: 36px;
    }

    .onboarding-call {
      margin-bottom: 24px;
    }

    .plan-button {
      height: 28px;
      max-height: 28px;
      min-height: 28px;
    }
    .ad-spend-input {
      height: 48px;
    }
</style>
