class PlanType {
  constructor(value, name, level) {
    this.name = name;
    this.value = value;
    this.level = level;
  }
}

const PLAN_TYPES = [
  new PlanType('BASIC', 'Basic', 0),
  new PlanType('PLUS', 'Plus', 1),
  new PlanType('ADVANCED', 'Advanced', 2),
  new PlanType('ENTERPRISE', 'Enterprise', 3),
];

const findPlanByType = (type) => PLAN_TYPES.find((acc) => acc.value === type);

const findNextPlan = (selectedPlan) => {
  const selectedPlanType = PLAN_TYPES.find((plan) => selectedPlan.planType === plan.value);
  return PLAN_TYPES.find((plan) => plan.level === selectedPlanType.level + 1);
};

export { PLAN_TYPES, findPlanByType, findNextPlan };
