<template>
  <div class="text-center hidden">
    <h2>You have no active subscription</h2>
    <p>In order to use the app, you need to enroll in a plan.</p>
  </div>
  <PlanSelector />
</template>

<script>

import PlanSelector from '../plan/PlanSelector.vue';

export default {
  components: {
    PlanSelector,
  },
  inject: ['query'],
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    selectedProfile: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['clear-selected-profile', 'change-menu-layout'],
  data() {
    return {

    };
  },
  computed: {

  },
  watch: {
  },
  methods: {

  },
};
</script>
